import React from "react";
import logo from "./assets/svg/logo-full.svg";
import appBg from "./assets/svg/unDraw/progressive_app_m9ms.svg";
import solutionsBg from "./assets/svg/unDraw/solution_mindset_34bi.svg";

function App() {
  return (
    <div className="h-screen flex flex-col justify-center items-center">
      <img
        className="w-11/12 sm:w-1/2 xl:w-1/3 fixed top-1/4 transition-all duration-500"
        src={appBg}
        alt="unDraw Solution Mindset"
        style={{ opacity: 0.04, zIndex: -1, top: "5vh", left: "10vw" }}
      />
      <img
        className="w-11/12 sm:w-1/2 xl:w-1/3 fixed top-3/4 transition-all duration-500"
        src={solutionsBg}
        alt="unDraw Solution Mindset"
        style={{ opacity: 0.04, zIndex: -1, bottom: "5vh", right: "10vw" }}
      />

      <img
        className="sm:w-1/2 lg:w-1/3 transition-all duration-500"
        src={logo}
        alt="ScryptWorx logo"
      />
      <div className="px-6 py-4">
        <div className="text-gray-700 text-center font-bold text-xl mb-2 tracking-wider">
          creating intuitive software solutions
        </div>
      </div>
      <div className="px-6 py-4">
        {["Amplify", "React", "GraphQL"].map((tag) => (
          <p
            key={tag}
            onClick={() => {
              if (tag === "React")
                window.open(
                  "https://reactjs.org/",
                  "_blank",
                  "noreferrer noopener"
                );
              if (tag === "Amplify")
                window.open(
                  "https://docs.amplify.aws/",
                  "_blank",
                  "noreferrer noopener"
                );
              if (tag === "GraphQL")
                window.open(
                  "https://graphql.org//",
                  "_blank",
                  "noreferrer noopener"
                );
            }}
            className="sm:inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mt-2 cursor-pointer"
          >
            {"#" + tag}
          </p>
        ))}
      </div>
      <div className="fixed bottom-0 p-4 text-gray-500">
        &copy; ScryptWorx 2021
      </div>
    </div>
  );
}

export default App;
